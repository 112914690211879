import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import GoogleLoginButton from "../../component/googleLoginButton";
import { fetchUser } from "../../store/userSilice";

const Login = () => {
  const { loginStatus, loading } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, []);
  useEffect(() => {
    if (loginStatus === "authorized") {
      navigate("/");
    }
  }, [loginStatus, navigate]);
  return (
    <>
      <div className="row g-0 align-items-center">
        <div className="col-auto">
          <div className="login-form">
            <div>
              <img src={"https://www.nuctro.com/assets/brand.png"} />{" "}
            </div>
            <h3>Login to your account</h3>
              <GoogleLoginButton loginStatus={loginStatus} />
          </div>
        </div>
        <div className="col">
          <div className="login-side">
            <div className="text">
              <h2>Your Story Awaits. Start Writing.</h2>
              <h3>
                Welcome back, storyteller. Let’s continue where you left off.
              </h3>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
