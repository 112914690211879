import "./editorTheme.css";

const theme = {
  blockCursor: "nuctro__blockCursor",
  characterLimit: "nuctro__characterLimit",
  code: "nuctro__code",
  codeHighlight: {
    atrule: "nuctro__tokenAttr",
    attr: "nuctro__tokenAttr",
    boolean: "nuctro__tokenProperty",
    builtin: "nuctro__tokenSelector",
    cdata: "nuctro__tokenComment",
    char: "nuctro__tokenSelector",
    class: "nuctro__tokenFunction",
    "class-name": "nuctro__tokenFunction",
    comment: "nuctro__tokenComment",
    constant: "nuctro__tokenProperty",
    deleted: "nuctro__tokenProperty",
    doctype: "nuctro__tokenComment",
    entity: "nuctro__tokenOperator",
    function: "nuctro__tokenFunction",
    important: "nuctro__tokenVariable",
    inserted: "nuctro__tokenSelector",
    keyword: "nuctro__tokenAttr",
    namespace: "nuctro__tokenVariable",
    number: "nuctro__tokenProperty",
    operator: "nuctro__tokenOperator",
    prolog: "nuctro__tokenComment",
    property: "nuctro__tokenProperty",
    punctuation: "nuctro__tokenPunctuation",
    regex: "nuctro__tokenVariable",
    selector: "nuctro__tokenSelector",
    string: "nuctro__tokenSelector",
    symbol: "nuctro__tokenProperty",
    tag: "nuctro__tokenProperty",
    url: "nuctro__tokenOperator",
    variable: "nuctro__tokenVariable",
  },
  embedBlock: {
    base: "nuctro__embedBlock",
    focus: "nuctro__embedBlockFocus",
  },
  hashtag: "nuctro__hashtag",
  heading: {
    h1: "nuctro__h1",
    h2: "nuctro__h2",
    h3: "nuctro__h3",
    h4: "nuctro__h4",
    h5: "nuctro__h5",
    h6: "nuctro__h6",
  },
  image: "editor-image",
  indent: "nuctro__indent",
  inlineImage: "inline-editor-image",
  layoutContainer: "nuctro__layoutContainer",
  layoutItem: "nuctro__layoutItem",
  link: "nuctro__link",
  list: {
    checklist: "nuctro__checklist",
    listitem: "nuctro__listItem",
    listitemChecked: "nuctro__listItemChecked",
    listitemUnchecked: "nuctro__listItemUnchecked",
    nested: {
      listitem: "nuctro__nestedListItem",
    },
    olDepth: [
      "nuctro__ol1",
      "nuctro__ol2",
      "nuctro__ol3",
      "nuctro__ol4",
      "nuctro__ol5",
    ],
    ul: "nuctro__ul",
  },
  ltr: "nuctro__ltr",
  mark: "nuctro__mark",
  markOverlap: "nuctro__markOverlap",
  paragraph: "nuctro__paragraph",
  quote: "nuctro__quote",
  rtl: "nuctro__rtl",
  table: "nuctro__table",
  tableAddColumns: "nuctro__tableAddColumns",
  tableAddRows: "nuctro__tableAddRows",
  tableCell: "nuctro__tableCell",
  tableCellActionButton: "nuctro__tableCellActionButton",
  tableCellActionButtonContainer: "nuctro__tableCellActionButtonContainer",
  tableCellEditing: "nuctro__tableCellEditing",
  tableCellHeader: "nuctro__tableCellHeader",
  tableCellPrimarySelected: "nuctro__tableCellPrimarySelected",
  tableCellResizer: "nuctro__tableCellResizer",
  tableCellSelected: "nuctro__tableCellSelected",
  tableCellSortedIndicator: "nuctro__tableCellSortedIndicator",
  tableResizeRuler: "nuctro__tableCellResizeRuler",
  tableSelected: "nuctro__tableSelected",
  tableSelection: "nuctro__tableSelection",
  text: {
    bold: "nuctro__textBold",
    code: "nuctro__textCode",
    italic: "nuctro__textItalic",
    strikethrough: "nuctro__textStrikethrough",
    subscript: "nuctro__textSubscript",
    superscript: "nuctro__textSuperscript",
    underline: "nuctro__textUnderline",
    underlineStrikethrough: "nuctro__textUnderlineStrikethrough",
  },
};

export default theme;
