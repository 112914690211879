import "./style.css";

import initCroper from "lego-image";
import { useEffect, useRef, useState } from "react";

const ImageEditor = ({ imageToEdit, updateImage, closeEditor }) => {
  const [subscribe, setSubscribe] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    // Check if ref exists and subscribe state is false
    if (ref.current && !subscribe) {
      // Set subscribe state to true
      setSubscribe(true);

      // Initialize lego-image with configuration
      const canvas = document.getElementById("canvas");
      const cropper = initCroper({
        canvas: canvas,
        onChange: ()=>{}
      });

      if (imageToEdit) {
        const imageUrl = URL.createObjectURL(imageToEdit);
        cropper.setCorper({
          imageUrl: imageUrl,
          pos: { x: 0.5, y: 0.5 },
          borderLess: false,
        });
      }
    }
    return () => {};
  }, [imageToEdit]);

  const saveImage = () => {
    const croppedImageUrl = document
      .getElementById("canvas")
      .toDataURL("image/jpeg");
    updateImage(croppedImageUrl);
  };
  return (
    <>
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit image</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="canvasContainer" style={{ margin: "0 auto" }}>
                <canvas id="canvas" ref={ref}></canvas>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={saveImage}
              >
                Save changes
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={closeEditor}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ImageEditor;
