import ImageEditor from "../../component/imageEditor";
import { MultiSelectInput } from "../../component/multSelectInput";
import "./style.css";

import { useEffect, useState } from "react";

const UserProfile = () => {
  const [userDetail, setUserDetail] = useState({});
  const [userDetailToEdit, setUserDetailToEdit] = useState({});
  const [imageToEdit, setImageToEdit] = useState();

  const [edit, setEdit] = useState(false);

  useEffect(() => {
    fetch("/api/v2/user-profile", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        credentials: 'include'
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setUserDetail(response.data);
        setUserDetailToEdit(response.data);
      });
  }, []);
  const updloadImage = (value) => {
    fetch(`/api/v2/edit-profile-image-editor`, {
      method: "POST",
      body: JSON.stringify({ image: value }),
    })
      .then((response) => response.json())
      .then((res) => {
        setImageToEdit("");
        setUserDetail({ ...userDetail, image: res.image });
      });
  };
  const updateUserDetail = () => {
    fetch(`/api/v2/edit-profile-editor`, {
      method: "POST",
      body: JSON.stringify(userDetailToEdit),
    })
      .then((response) => response.json())
      .then((res) => {
        setEdit(false);
        setUserDetail(userDetailToEdit);
      });
  };
  const cancelEdit = () => {
    setEdit(false);
    setUserDetailToEdit(userDetail);
  };
  const handleOnChange = (e)=>{
    setUserDetailToEdit({...userDetailToEdit, [e.target.name]: e.target.value})
  }
  return (
    <>
      <div className="card-block user-profile">
        <div className="row">
          <div className="col-auto">
            <div className="profile-image">
              <img src={userDetail.image} />
              <div className="change-profile">
                Change Profile Picture
                <input
                  type={"file"}
                  onChange={(event) => setImageToEdit(event.target.files[0])}
                />
              </div>
            </div>
            <div className="edit-image">
              Edit image
              <input
                type={"file"}
                onChange={(event) => setImageToEdit(event.target.files[0])}
              />
            </div>
            <div className="edit-buttons">
              {!edit && (
                <button onClick={() => setEdit(true)}>Edit profile</button>
              )}
              {edit && (
                <>
                  <button onClick={cancelEdit}>Cancel edit</button>
                  <button onClick={updateUserDetail}>Update profile</button>
                </>
              )}
            </div>
          </div>
          {edit && (
            <div className="col">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Name</label>
                <input
                  // value={post["blog_title"]}
                  type="text"
                  name="name"
                  className="form-control"
                  value={userDetailToEdit.name}
                  onChange={handleOnChange}
                  aria-describedby=""
                  placeholder="Enter title"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Profile title</label>
                <input
                  // value={post["blog_title"]}
                  type="text"
                  name="profile_title"
                  className="form-control"
                  value={userDetailToEdit.profile_title}
                  onChange={handleOnChange}
                  aria-describedby=""
                  placeholder="Enter title"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  Job/Position/Degination
                </label>
                <input
                  // value={post["blog_title"]}
                  type="text"
                  name="position"
                  className="form-control"
                  value={userDetailToEdit.position}
                  onChange={handleOnChange}

                  aria-describedby=""
                  placeholder="Enter title"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Biography</label>
                <input
                  value={userDetailToEdit.biography}
                  onChange={handleOnChange}

                  type="text"
                  name="biography"
                  className="form-control"
                  aria-describedby=""
                  placeholder="Enter title"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Category of blog</label>
                <MultiSelectInput
                  options={[userDetailToEdit.country]}
                  maxSelection={4}
                  value={[userDetailToEdit.country]}
                  onChange={(value) => setUserDetailToEdit({...userDetailToEdit, country: value})}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Website title</label>
                <input
                  value={userDetailToEdit.website_title}
                  type="text"
                  name="website_title"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  placeholder="Enter title"
                  onChange={handleOnChange}

                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Website url</label>
                <input
                  // value={post["blog_title"]}
                  type="text"
                  name="website_url"
                  className="form-control"
                  id=""
                  value={userDetailToEdit.website_url}
                  aria-describedby=""
                  placeholder="Enter title"
                  onChange={handleOnChange}

                />
              </div>
            </div>
          )}

          {!edit && (
            <div className="col">
              <small>Name</small>

              <p className="user-info-box">{userDetail.name}</p>
              <small>User Id</small>
              <p className="user-info-box">@{userDetail.user_id}</p>
              <small>Headline</small>
              <p className="user-info-box">
                {userDetail.profile_title || "N/A"}
              </p>
              <small>Job/Current position</small>
              <p className="user-info-box">{userDetail.position || "N/A"}</p>
              <small>About you</small>
              <p className="user-info-box">{userDetail.biography || "N/A"}</p>
              <small>DOB (Not visible to other)</small>
              <p className="user-info-box">{userDetail.dob || "N/A"}</p>
              <small>Country</small>
              <p className="user-info-box">
                {userDetail.country?.option || "N/A"}
              </p>
              <small>Website title</small>
              <p className="user-info-box">
                {userDetail.website_title || "N/A"}
              </p>
              <small>Website url</small>
              <p className="user-info-box">{userDetail.website_url || "N/A"}</p>
            </div>
          )}
        </div>
      </div>
      {imageToEdit && (
        <ImageEditor
          imageToEdit={imageToEdit}
          updateImage={updloadImage}
          closeEditor={() => setImageToEdit("")}
        />
      )}
    </>
  );
};

export default UserProfile;
