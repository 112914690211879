import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../../images/menus.png";
import { useState } from "react";
import { GoogleLogOutButton } from "../googleLoginButton";

const Navbar = () => {
  const user = useSelector((state) => state.user);

  return (
    <nav className="navbar">
      <div className="container-fluid">
        <div className="row align-center">
          <div className="col-auto brand-container">
            <a href="https://www.nuctro.com/">
              <img
                className="brand-image"
                src={"https://www.nuctro.com/assets/brand.png"}
              />
            </a>
            <a href="https://www.nuctro.com/">
              <h2 className="brand-text">Nuctro</h2>
            </a>
          </div>

          <div className="col-auto align-content-center ms-auto user-profile-name">
            <a href={`https://www.nuctro.com/@${user.user_id}`}>
              <span className="nav-user-name">{user.name}</span>
            </a>
          </div>
          <div className="col-auto user-profile-image">
            <a href={`https://www.nuctro.com/@${user.user_id}`}>
              <img className="nav-profile-image" src={user.image} />
            </a>
          </div>
          <MenuSidebar />
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
const MenuSidebar = () => {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.user);
  return (
    <>
      <div className="col-auto sidebar-menu">
        <img className="" src={Menu} onClick={() => setOpen(!open)} />
      </div>
      {open && (
        <div className="sidebar-list">
          <div className="menu">
            <ul>
              <li>
                <Link to={`/`}>
                  {/* <i
                                    style={{ color: "#ff6d6d" }}
                                    className="fa fa-solid fa-house"
                                  ></i>{" "} */}
                  <i
                    style={{ color: "#47ff7f" }}
                    className="fa-solid fa-square-plus"
                  ></i>{" "}
                  Create Blog
                </Link>
              </li>
              <li>
                <Link to={`/blogs`}>
                  <i
                    style={{ color: "#ff47b4" }}
                    className="fa-solid fa-blog"
                  ></i>{" "}
                  Blogs
                </Link>
              </li>
              <li>
                <Link to={`/unpublished-blogs`}>
                  <i
                    style={{ color: "#47ceff" }}
                    className="fa-solid fa-eye-slash"
                  ></i>{" "}
                  Unpublished Blogs
                </Link>
              </li>
              {/* <li>
                                <Link to={`/create-blog`}>
                                  <i
                                    style={{ color: "#47ff7f" }}
                                    className="fa-solid fa-square-plus"
                                  ></i>{" "}
                                  Create Blogs
                                </Link>
                              </li> */}
            </ul>
          </div>
          <div className="menu">
            <ul>
              <li>
                <a href={`https://www.nuctro.com/@${user.user_id}`}>
                  <i
                    className="fa-solid fa-user"
                    style={{ color: "#ffc414" }}
                  ></i>{" "}
                  profile
                </a>
              </li>
              
              <li>
                <GoogleLogOutButton>
                  <>
                    <i
                      className="fa-solid fa-right-from-bracket"
                      style={{ color: "#ff4040" }}
                    ></i>{" "}
                    Logout
                  </>
                </GoogleLogOutButton>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
