import { $generateHtmlFromNodes } from "@lexical/html";
import { $applyNodeReplacement, createEditor, DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

const ImageComponent = React.lazy(() => import("./imageComponent"));

export class ImageNode extends DecoratorNode {
  __src;
  __altText;
  __width;
  __height;
  __maxWidth;
  __showCaption;
  __caption;
  // Captions cannot yet be used within editor cells
  __captionsEnable;
  __captionHtml;

  static getType() {
    return "image";
  }

  static clone(node) {
    return new ImageNode(
      node.__src,
      node.__altText,
      node.__maxWidth,
      node.__width,
      node.__height,
      node.__showCaption,
      node.__caption,
      node.__captionsEnabled,
      node.__key,
      node.__captionHtml,
    );
  }

  // static importJSON(serializedNode) {
  //   console.log(serializedNode)
  //   const { altText, height, width, maxWidth, caption, src, showCaption } =
  //     serializedNode;
  //   const node = $createImageNode({
  //     altText,
  //     height,
  //     maxWidth,
  //     showCaption,
  //     src,
  //     width,
  //   });
  //   const nestedEditor = node.__caption;
  //   const editorState = nestedEditor.parseEditorState(caption.editorState);
  //   if (!editorState.isEmpty()) {
  //     nestedEditor.setEditorState(editorState);
  //   }
  //   return node;
  // }
  exportJSON() {
    return {
      altText: this.getAltText(),
      caption: this.__caption.toJSON(),
      height: this.__height === "inherit" ? 0 : this.__height,
      maxWidth: this.__maxWidth,
      showCaption: this.__showCaption,
      src: this.getSrc(),
      type: "image",
      version: 1,
      width: this.__width === "inherit" ? 0 : this.__width,
    };
  }
  exportDOM() {
    const element = document.createElement("div");
    const div = document.createElement("div");
    const img = document.createElement("img");
    element.setAttribute("data-lexical-image-container", `true`);
    const regex = /^https?:\/\/[^\/]+(\/.*)/;
    let imageUrl = this.__src;
    const match = imageUrl.match(regex);
    if (match) {
      imageUrl = match[1]; // Extract the captured path
    }
    img.setAttribute("src", imageUrl);
    img.setAttribute("alt", this.__altText);
    img.setAttribute("width", this.__width.toString());
    img.setAttribute("height", this.__height.toString());
    img.setAttribute("data-lexical-image", `true`);
    console.log(img);
    const span = document.createElement("p");
    span.setAttribute("data-lexical-caption", `true`);
    element.appendChild(img);
    if (this.__captionHtml) {
      div.innerHTML = this.__captionHtml;
      if (div.querySelector("p")) {
        element.appendChild(span);
        span.innerHTML = div.querySelector("p").innerHTML;
      }
    }
    return { element };
  }

  static importDOM() {
    return {
      img: (node) => {
        if (!node.hasAttribute("data-lexical-image")) {
          return null;
        }
        return {
          conversion: (domNode) => {
            const div = domNode.parentElement;
            const p = div.querySelector("p");
            const { alt: altText, src, width, height } = domNode;
            let captionHtml = "";
            let showCaption = false;
            if (p) {
              const newP = document.createElement("p");
              newP.innerHTML = p.innerHTML;
              console.log(newP);

              captionHtml = newP.outerHTML;
              showCaption = true;
            }
            const node = $createImageNode({
              altText,
              height,
              src,
              width,
              showCaption,
              captionHtml,
            });
            return { node };
          },
          priority: 1,
        };
      },
      p: (node) => {
        if (!node.hasAttribute("data-lexical-caption")) {
          return null;
        }
        return {
          conversion: (domNode) => {
            console.log(domNode);
            return { node: "" };
          },
          priority: 4,
        };
      },
    };
  }

  constructor(
    src,
    altText,
    maxWidth,
    width,
    height,
    showCaption,
    caption,
    captionsEnabled,
    key,
    captionHtml,
  ) {
    super(key);
    this.__src = src;
    this.__altText = altText;
    this.__maxWidth = maxWidth;
    this.__width = width || "";
    this.__height = height || "";
    this.__showCaption = showCaption || false;
    this.__caption = caption || createEditor();
    this.__captionsEnabled = captionsEnabled || captionsEnabled === undefined;
    this.__captionHtml = captionHtml;
  }

  setWidthAndHeight(width, height) {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  setShowCaption(showCaption) {
    const writable = this.getWritable();
    writable.__showCaption = showCaption;
  }
  setCaptionHtml(captionHtml) {
    const writable = this.getWritable();
    writable.__captionHtml = captionHtml;
  }
  // View

  createDOM(config) {
    const span = document.createElement("p");
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  updateDOM() {
    return false;
  }

  getSrc() {
    return this.__src;
  }

  getAltText() {
    return this.__altText;
  }

  decorate() {
    return (
      <Suspense fallback={null}>
        <ImageComponent
          src={this.__src}
          altText={this.__altText}
          width={this.__width}
          height={this.__height}
          maxWidth={this.__maxWidth}
          nodeKey={this.getKey()}
          showCaption={this.__showCaption}
          caption={this.__caption}
          captionHtml={this.__captionHtml}
          captionsEnabled={this.__captionsEnabled}
          resizable={true}
        />
      </Suspense>
    );
  }
}

export function $createImageNode({
  altText,
  height,
  maxWidth,
  captionsEnabled,
  src,
  width,
  showCaption,
  caption,
  key,
  captionHtml,
}) {
  return $applyNodeReplacement(
    new ImageNode(
      src,
      altText,
      maxWidth,
      width,
      height,
      showCaption,
      caption,
      captionsEnabled,
      key,
      captionHtml,
    ),
  );
}

export function $isImageNode(node) {
  return node instanceof ImageNode;
}
