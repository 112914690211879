import { useEffect, useState } from "react";
import _ from 'lodash';

export const MultiSelectInput = ({
  options,
  onChange,
  maxSelection = 5,
  value = [],
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isFocused, setIsFocused] = useState(false); // Track input focus state

  // Add a tag to the selection
  useEffect(() => {
    if (!_.isEqual(selectedOptions, value)) {
      setSelectedOptions(value);
    }
  }, [value, selectedOptions]);
  const addTag = (option) => {
    if (
      !selectedOptions.some((selected) => selected.key === option.key) &&
      selectedOptions.length < maxSelection
    ) {
      let newSelectedOptions = [...selectedOptions, option];
      setSelectedOptions([...newSelectedOptions]); // Update state with new option
      setSearchTerm("");
      setFilteredOptions(options);
      onChange([...newSelectedOptions]);
    }
  };

  // Remove a tag from the selection
  const removeTag = (optionKey) => {
    let newSelectedOptions = selectedOptions.filter(
      (item) => item.key !== optionKey
    );
    setSelectedOptions(newSelectedOptions);
    onChange([...newSelectedOptions]);
  };

  // Handle input changes (filtering options)
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFilteredOptions(
      options.filter((option) =>
        option.option.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  // Handle backspace key to remove the last tag
  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && searchTerm === "" && selectedOptions.length) {
      removeTag(selectedOptions[selectedOptions.length - 1].key);
    }
  };

  // Handle input focus and blur events to toggle dropdown visibility
  console.log(selectedOptions);
  return (
    <div className="multi-select-input">
      <div className="input-wrapper" tabIndex={0}>
        {/* Render selected options as tags */}
        {selectedOptions.map((option) => (
          <span key={option.key} className="tag">
            {option.flag} {option.option}
            <button
              onClick={() => removeTag(option.key)}
              className="remove-button"
            >
              &times;
            </button>
          </span>
        ))}

        {/* Input field for search and selection */}
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Search and select..."
          className="inline-input"
          disabled={selectedOptions.length >= maxSelection} // Disable input if max limit reached
          onFocus={() => setIsFocused(true)}
          onBlur={(e) => {
            // Set a small timeout to handle onBlur before dropdown click
            setTimeout(() => setIsFocused(false), 300);
          }}
        />
      </div>

      {/* Show dropdown only when input is focused and search term is not empty */}
      {isFocused && filteredOptions.length > 0 && (
        <ul className="dropdown" onClick={(e) => e.stopPropagation()}>
          {filteredOptions.map((option) => (
            <li
              key={option.key}
              onClick={() => addTag(option)}
              className={
                selectedOptions.some((selected) => selected.key === option.key)
                  ? "selected"
                  : ""
              }
            >
              {option.flag} {option.option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
