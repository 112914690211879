import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import loaderEvent from "../component/loader/loaderEmitter";

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  let response = await fetch("/api/v2/user-profile");
  response = await response.json();
  return response;
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    user_id: "",
    loginStatus: "",
    loading: true
  },
  extraReducers(builder) {
    builder
      // eslint-disable-next-line no-unused-vars
      .addCase(fetchUser.pending, (state, action) => {
        state.loginStatus = "";
        state.loading= true
        // loaderEvent.emit("showLoader")
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        if (action.payload.status === 1) {
          state.loginStatus = "authorized";
          state.image = action.payload.data.image;
          state.name = action.payload.data.name;
          state.user_id = action.payload.data.user_id;

        } else {
          state.loginStatus = "login-required";
        }
        state.loading = false
        // loaderEvent.emit("hideLoader")
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(fetchUser.rejected, (state, action) => {});
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = userSlice.actions;
export default userSlice.reducer;
