import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchUser } from "../../store/userSilice";

const PrivateRoute = ({ children }) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const url = "https://accounts.google.com/gsi/client";
    const script = document.createElement("script");
    script.async = true;
    script.src = url;
    document.head.appendChild(script);
    if (!user.loginStatus) {
      dispatch(fetchUser());
    } else if (user.loginStatus === "login-required") {
      window.location.href = "https://nuctro.com";
    }
  }, [dispatch, navigate, user]);
  if (user.loginStatus === "authorized") {
    return children;
  } else {
    return <></>;
  }
};
export default PrivateRoute;
