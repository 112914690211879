export const categoryList = [
    { "key": "lifestyle", "option": "Lifestyle" },
    { "key": "personal_development", "option": "Personal Development" },
    { "key": "health_wellness", "option": "Health & Wellness" },
    { "key": "fitness", "option": "Fitness" },
    { "key": "nutrition", "option": "Nutrition" },
    { "key": "fashion", "option": "Fashion" },
    { "key": "beauty", "option": "Beauty" },
    { "key": "travel", "option": "Travel" },
    { "key": "home_garden", "option": "Home & Garden" },
    { "key": "parenting", "option": "Parenting" },
    { "key": "relationships", "option": "Relationships" },
    { "key": "mindfulness", "option": "Mindfulness" },
    { "key": "minimalism", "option": "Minimalism" },
    { "key": "sustainable_living", "option": "Sustainable Living" },
  
    { "key": "technology", "option": "Technology" },
    { "key": "web_development", "option": "Web Development" },
    { "key": "javascript", "option": "JavaScript" },
    { "key": "react", "option": "React" },
    { "key": "angular", "option": "Angular" },
    { "key": "vue", "option": "Vue" },
    { "key": "html_css", "option": "HTML & CSS" },
    { "key": "mobile_development", "option": "Mobile Development" },
    { "key": "android", "option": "Android" },
    { "key": "ios", "option": "iOS" },
    { "key": "ui_ux_design", "option": "UI/UX Design" },
    { "key": "artificial_intelligence", "option": "Artificial Intelligence" },
    { "key": "machine_learning", "option": "Machine Learning" },
    { "key": "cybersecurity", "option": "Cybersecurity" },
    { "key": "blockchain", "option": "Blockchain" },
    { "key": "internet_of_things", "option": "Internet of Things" },
    { "key": "virtual_reality", "option": "Virtual Reality" },
    { "key": "augmented_reality", "option": "Augmented Reality" },
    { "key": "data_science", "option": "Data Science" },
    { "key": "big_data", "option": "Big Data" },
    { "key": "programming", "option": "Programming" },
    { "key": "devops", "option": "DevOps" },
    { "key": "tech_news", "option": "Tech News" },
    { "key": "gadgets", "option": "Gadgets" },
    { "key": "software", "option": "Software" },
  
    { "key": "programming_languages", "option": "Programming Languages" },
    { "key": "python", "option": "Python" },
    { "key": "java", "option": "Java" },
    { "key": "csharp", "option": "C#" },
    { "key": "cplusplus", "option": "C++" },
    { "key": "typescript", "option": "TypeScript" },
    { "key": "ruby", "option": "Ruby" },
    { "key": "swift", "option": "Swift" },
    { "key": "go", "option": "Go" },
    { "key": "php", "option": "PHP" },
    { "key": "rust", "option": "Rust" },
    { "key": "perl", "option": "Perl" },
  
    { "key": "science", "option": "Science" },
    { "key": "physics", "option": "Physics" },
    { "key": "chemistry", "option": "Chemistry" },
    { "key": "biology", "option": "Biology" },
    { "key": "astronomy", "option": "Astronomy" },
    { "key": "earth_science", "option": "Earth Science" },
    { "key": "environmental_science", "option": "Environmental Science" },
    { "key": "genetics", "option": "Genetics" },
    { "key": "neuroscience", "option": "Neuroscience" },
    { "key": "botany", "option": "Botany" },
    { "key": "zoology", "option": "Zoology" },
    { "key": "ecology", "option": "Ecology" },
    { "key": "geology", "option": "Geology" },
    { "key": "paleontology", "option": "Paleontology" },
    { "key": "oceanography", "option": "Oceanography" },
    { "key": "meteorology", "option": "Meteorology" },
    { "key": "anthropology", "option": "Anthropology" },
  
    { "key": "business", "option": "Business" },
    { "key": "entrepreneurship", "option": "Entrepreneurship" },
    { "key": "startups", "option": "Startups" },
    { "key": "marketing", "option": "Marketing" },
    { "key": "social_media", "option": "Social Media" },
    { "key": "content_marketing", "option": "Content Marketing" },
    { "key": "personal_finance", "option": "Personal Finance" },
    { "key": "investing", "option": "Investing" },
    { "key": "ecommerce", "option": "E-commerce" },
    { "key": "freelancing", "option": "Freelancing" },
    { "key": "strategy", "option": "Strategy" },
    { "key": "productivity", "option": "Productivity" },
    { "key": "career_development", "option": "Career Development" },
    { "key": "real_estate", "option": "Real Estate" },
    { "key": "financial_planning", "option": "Financial Planning" },
  
    { "key": "education", "option": "Education" },
    { "key": "online_learning", "option": "Online Learning" },
    { "key": "study_tips", "option": "Study Tips" },
    { "key": "educational_technology", "option": "Educational Technology" },
    { "key": "teaching_resources", "option": "Teaching Resources" },
    { "key": "language_learning", "option": "Language Learning" },
    { "key": "college_advice", "option": "College Advice" },
    { "key": "educational_policy", "option": "Educational Policy" },
  
    { "key": "entertainment", "option": "Entertainment" },
    { "key": "movies", "option": "Movies" },
    { "key": "tv_shows", "option": "TV Shows" },
    { "key": "music", "option": "Music" },
    { "key": "video_games", "option": "Video Games" },
    { "key": "celebrity_news", "option": "Celebrity News" },
    { "key": "pop_culture", "option": "Pop Culture" },
    { "key": "books", "option": "Books" },
    { "key": "theater", "option": "Theater" },
    { "key": "art_design", "option": "Art & Design" },
  
    { "key": "food_drink", "option": "Food & Drink" },
    { "key": "recipes", "option": "Recipes" },
    { "key": "cooking_tips", "option": "Cooking Tips" },
    { "key": "food_photography", "option": "Food Photography" },
    { "key": "restaurant_reviews", "option": "Restaurant Reviews" },
    { "key": "culinary_traditions", "option": "Culinary Traditions" },
    { "key": "dietary_restrictions", "option": "Dietary Restrictions" },
    { "key": "wine_spirits", "option": "Wine & Spirits" },
    { "key": "food_history", "option": "Food History" },
  
    { "key": "sports", "option": "Sports" },
    { "key": "team_sports", "option": "Team Sports" },
    { "key": "individual_sports", "option": "Individual Sports" },
    { "key": "fitness_training", "option": "Fitness Training" },
    { "key": "extreme_sports", "option": "Extreme Sports" }
  ]
  