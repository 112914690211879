// src/components/Loader.js
import React, { useEffect, useState } from "react";
import "./style.css";
import loaderEvent from "./loaderEmitter";

function LoaderContainer() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const show = () => setVisible(true);
    const hide = () => setVisible(false);

    loaderEvent.on("showLoader", show);
    loaderEvent.on("hideLoader", hide);

    return () => {
      loaderEvent.off("showLoader", show);
      loaderEvent.off("hideLoader", hide);
    };
  }, []);

  if (!visible) return null;

  return (
    <div className="loader-overlay">
      <div className="loader-spinner"/>
    </div>
  );

  return (
    <div className="loader-overlay">
      <div className="loader-spinner">Loading...</div>
    </div>
  );
}

export default LoaderContainer;
