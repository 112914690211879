/* eslint-disable react/react-in-jsx-scope */
import "./stylesheet/style.css";

import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";

import { GoogleLogOutButton } from "./component/googleLoginButton";
import Navbar from "./component/navbar";
import PrivateRoute from "./component/privateRoute";
import Blogs from "./page/blogs";
import CreateBlog from "./page/createBlog";
import EditBlog from "./page/editBlog";
import EditBlogContent from "./page/editBlogcontent";
import Home from "./page/home";
import Login from "./page/login";
import UserProfile from "./page/userProfile";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoaderContainer from "./component/loader";
function App() {
  const user = useSelector((state) => state.user);
  console.log(user)
  return (
    <>
    <LoaderContainer/>
    <ToastContainer/>
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <div className="App">
                <div className="row gx-0 flex-column page-container">
                  <div className="col-auto">
                    <Navbar />
                  </div>
                  <div className="col">
                    <div className="row  gx-0 page-area-conatiner">
                      <div className="col-auto">
                        <div className="sidebar">
                          <div className="menu">
                            <ul>
                              <li>
                                <Link to={`/`}>
                                  {/* <i
                                    style={{ color: "#ff6d6d" }}
                                    className="fa fa-solid fa-house"
                                  ></i>{" "} */}
                                  <i
                                    style={{ color: "#47ff7f" }}
                                    className="fa-solid fa-square-plus"
                                  ></i>{" "}
                                  Create Blog
                                </Link>
                              </li>
                              <li>
                                <Link to={`/blogs`}>
                                  <i
                                    style={{ color: "#ff47b4" }}
                                    className="fa-solid fa-blog"
                                  ></i>{" "}
                                  Blogs
                                </Link>
                              </li>
                              <li>
                                <Link to={`/unpublished-blogs`}>
                                  <i
                                    style={{ color: "#47ceff" }}
                                    className="fa-solid fa-eye-slash"
                                  ></i>{" "}
                                  Unpublished Blogs
                                </Link>
                              </li>
                              {/* <li>
                                <Link to={`/create-blog`}>
                                  <i
                                    style={{ color: "#47ff7f" }}
                                    className="fa-solid fa-square-plus"
                                  ></i>{" "}
                                  Create Blogs
                                </Link>
                              </li> */}
                            </ul>
                          </div>
                          <div className="menu">
                            <ul>
                              <li>
                                <a href={`https://www.nuctro.com/@${user.user_id}`}>
                                  <i
                                    className="fa-solid fa-user"
                                    style={{ color: "#ffc414" }}
                                  ></i>{" "}
                                  profile
                                </a>
                              </li>
                              {/* <li>
                                <i
                                  className="fa-solid fa-chart-simple"
                                  style={{ color: "#a568ff" }}
                                ></i>{" "}
                                Analytics Reports
                              </li>
                              <li>
                                <i
                                  className="fa-solid fa-screwdriver-wrench"
                                  style={{ color: "#83ff63" }}
                                ></i>{" "}
                                Setting
                              </li> */}
                              <li>
                                <GoogleLogOutButton>
                                  <>
                                    <i
                                      className="fa-solid fa-right-from-bracket"
                                      style={{ color: "#ff4040" }}
                                    ></i>{" "}
                                    Logout
                                  </>
                                </GoogleLogOutButton>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col page-area">
                        <Routes>
                          <Route path="/" element={<CreateBlog />} />

                          <Route path="/blogs" element={<Blogs />} />
                          <Route
                            path="/unpublished-blogs"
                            element={<Blogs published={false} />}
                          />
                          <Route path="/create-blog" element={<CreateBlog />} />
                          <Route
                            path="/edit-blog/:blog_id"
                            element={<EditBlog />}
                          />
                          <Route
                            path="/edit-blog-content/:blog_id"
                            element={<EditBlogContent />}
                          />
                          <Route path="/profile" element={<UserProfile />} />
                        </Routes>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Editor/> */}
              </div>
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
    </>
    
  );
}

export default App;
