import { useState } from "react";

const DeleteBlogModal = ({ blog_id, deleteBlog }) => {
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <>
      <button
        className="delete-blog-button"
        onClick={() => {
          setShowConfirm(true);
        }}
      >
        Delete
      </button>
      {showConfirm && (
        <>
          <div className="confirm-modal-backdrop" onClick={()=>setShowConfirm(false)}/>
          <div className={"confirm-modal"}>
            <p>
              Are you sure you want to delete this item? This action cannot be
              undone.
            </p>
            <button
              className="delete-blog-button"
              onClick={() => {
                deleteBlog(blog_id);
                setShowConfirm(false);
              }}
            >
              Confirm
            </button>
            <button
              className="edit-blog-button"
              onClick={() => setShowConfirm(false)}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </>
  );
};
export default DeleteBlogModal;
