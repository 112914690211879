import "./style.css";

import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ImageEditor from "../../component/imageEditor";
import Editor from "../editor";
import { MultiSelectInput } from "../../component/multSelectInput";
import { categoryList } from "../../utils/categoryList";
import { useNavigate } from "react-router-dom";
import loaderEvent from "../../component/loader/loaderEmitter";

const CreateBlog = () => {
  const [post, setPost] = useState({});
  const [imageToEdit, setImageToEdit] = useState(null);
  const [blogId, setBlogId] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const inputPost = useCallback(
    (e) => {
      setPost({ ...post, [e.target.name]: e.target.value });
    },
    [post]
  );
  const handleImageChange = (event) => {
    setImageToEdit(event.target.files[0]);
    setPost({ ...post, blog_image: event.target.files[0] });
  };
  useEffect(() => {}, []);
  const validateFields = () => {
    let isValid = true;
    const requiredFields = {
      title: "Title of blog is required",
      description: "Description of blog is required",
      blog_image: "Image thumbnail is required",
      blog_category: "Category of blog is required",
      meta_keywords: "Meta tags of blog are required",
    };

    Object.entries(requiredFields).forEach(([key, message]) => {
      if (!post[key] || post[key].length === 0) {
        toast.error(message, { toastId: key });
        isValid = false;
      }
    });

    return isValid;
  };
  const submit = () => {
    if (validateFields()) {
      loaderEvent.emit("showLoader");
      if (!blogId) {
        fetch("/api/v2/create-post", {
          method: "POST",
          body: JSON.stringify(post),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.status === 1) {
              navigate(`/edit-blog-content/${response.data.post_id}`);
            }
          })
          .finally(() => {
            toast.success("Blog created - Start editing")
            loaderEvent.emit("hideLoader");
          });
      } else {
        fetch("/api/v2/update-post", {
          method: "POST",
          body: JSON.stringify({ ...post, blog_id: blogId }),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.status === 1) {
              toast.success("Blog updated!")
            }
          })
          .finally(() => {
            loaderEvent.emit("hideLoader");
          });
      }
    }
  };

  const saveImage = (img) => {
    setPost({ ...post, blog_image: img });
    setImageToEdit(null);
  };
  return (
    <>
      {imageToEdit && (
        <ImageEditor imageToEdit={imageToEdit} updateImage={saveImage} />
      )}
      <div className="row">
        <div className="col" style={{ overflow: "hidden" }}>
          {page === 1 && (
            <div className="card card-block">
              <div className="card-header card-block-header">
                <div className="col-12">
                  <h5>Create Blog</h5>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Title of blog <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={(e) => inputPost(e)}
                          value={post["title"]}
                          type="text"
                          name="title"
                          className="form-control"
                          id=""
                          aria-describedby=""
                          placeholder="Enter title"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Description of blog{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          onChange={inputPost}
                          value={post["description"]}
                          type="text"
                          name="description"
                          className="form-control"
                          id=""
                          aria-describedby=""
                          placeholder="Enter description"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-sm-auto">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Image thumbnail{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="image-thumbnail">
                            {post.blog_image ? (
                              <img src={post.blog_image} alt="Selected" />
                            ) : (
                              "Click here or drop your image to upload"
                            )}
                            <input
                              type="file"
                              className="image-input"
                              onChange={handleImageChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-sm">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Category of blog{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <MultiSelectInput
                                options={categoryList}
                                maxSelection={4}
                                value={post.blog_category}
                                onChange={(value) => {
                                  setPost({ ...post, blog_category: value });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Meta tags of blog{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <textarea
                                  onChange={inputPost}
                                  value={post["meta_keywords"]}
                                  type="meta_tag"
                                  name="meta_keywords"
                                  className="form-control"
                                  id=""
                                  aria-describedby=""
                                  placeholder="Enter meta tag"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Url of blog(optional e.g name-of-blog)
                                </label>
                                <input
                                  onChange={inputPost}
                                  value={post["blog_url"]}
                                  name="blog_url"
                                  className="form-control"
                                  id=""
                                  aria-describedby=""
                                  placeholder="blog URL"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12" style={{ marginTop: "20px" }}>
                            <button
                              className="blog-edit-button create-blog-button"
                              onClick={submit}
                            >
                              Start writing blog
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* {blogId && page === 2 && <Editor onChange={(e) => inputPost(e)} />} */}
        </div>
        {blogId && (
          <div className="col-auto">
            <div className="sidebar-post card-block"></div>
          </div>
        )}
      </div>
    </>
  );
};
export default CreateBlog;
