import { CodeNode } from "@lexical/code";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { TRANSFORMERS } from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { $getRoot } from "lexical";
import Prism from "prismjs";
import { useEffect, useState } from "react";

import { ImageNode } from "./nodes/imageNode";
import { YouTubeNode } from "./nodes/youtube";
import CodeActionMenuPlugin from "./plugins/CodeActionMenuPlugin";
import FloatingLinkEditorPlugin from "./plugins/floatingLinkEditorPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import ImagesPlugin from "./plugins/imagePlugin";
// import ExampleTheme from './ExampleTheme';
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import YouTubePlugin from "./plugins/youtubePlugin";
import theme from "./themes/editorTheme";

export const LoadInitialContent = ({ initialContent }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!initialContent) {
      return;
    }
    editor.update(() => {
      // In the browser you can use the native DOMParser API to parse the HTML string.
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialContent, "text/html");

      // Once you have the DOM instance it's easy to generate LexicalNodes.
      const nodes = $generateNodesFromDOM(editor, dom);

      const root = $getRoot();
      root.clear();
      root.append(...nodes);
    });
  }, [editor, initialContent]);
  return null;
};

// function LoadHtmlPlugin() {
//   const [editor] = useLexicalComposerContext();

//   editor.update(() => {
//     let nodes = [];
//     const html = localStorage.getItem("html");

//     // Parse html
//     if (html) {
//       const parser = new DOMParser();
//       const dom = parser.parseFromString(html, "text/html");
//       nodes = $generateNodesFromDOM(editor, dom);

//       // Set content
//       const root = $getRoot();
//       root.clear();
//       root.append(...nodes);
//     }
//   });

//   return null;
// }

function Placeholder() {
  return <div className="editor-placeholder">Enter some rich text...</div>;
}

const editorConfig = {
  namespace: "React.js Demo",
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  nodes: [
    HorizontalRuleNode,
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    ImageNode,
    YouTubeNode,
  ],
  // editorState: () => $convertFromMarkdownString(`## markdown`, TRANSFORMERS)
  // The editor theme
  theme: theme,
};

export default function Editor({ onChange, initialValue = "" }) {
  const isEditable = true;
  const [isLinkEditMode, setIsLinkEditMode] = useState(false);
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState(false);
  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        window && window.matchMedia("(max-width: 825px)").matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener("resize", updateViewPortWidth);

    return () => {
      window.removeEventListener("resize", updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);
  console.log("bogu", Prism.languages);
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={
              <div className="editor" ref={onRef}>
                <ContentEditable className="editor-content-editable" />
              </div>
            }
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <LoadInitialContent initialContent={initialValue} />

          {/* <LoadHtmlPlugin /> */}
          <OnChangePlugin
            onChange={(editorState, editor) => {
              editorState.read(() => {
                const html = $generateHtmlFromNodes(editor);
                onChange({ target: { name: "blog_content", value: html } });
              });
            }}
          />
          {!isEditable && <LexicalClickableLinkPlugin />}
          {floatingAnchorElem && !isSmallWidthViewport && (
            <>
              <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />

              <FloatingTextFormatToolbarPlugin
                anchorElem={floatingAnchorElem}
              />
              <FloatingLinkEditorPlugin
                anchorElem={floatingAnchorElem}
                isLinkEditMode={isLinkEditMode}
                setIsLinkEditMode={setIsLinkEditMode}
              />
            </>
          )}

          <YouTubePlugin />
          <ListPlugin />
          <LinkPlugin />
          <ImagesPlugin />
          <HistoryPlugin />
          <AutoFocusPlugin />
        </div>
      </div>
    </LexicalComposer>
  );
}
