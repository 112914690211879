import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import loaderEvent from "../component/loader/loaderEmitter";

export const fetchBlog = createAsyncThunk("post/fetchBlog", async (blog_id) => {
  let response = await fetch(`/api/v2/get-user-blog/${blog_id}`);
  response = await response.json();
  return response.data;
});

const blogSlice = createSlice({
  name: "blog",
  initialState: {
    loading: true,
    blog_id: "",
    blog_title: "",
    blog_description: "",
    blog_image: "",
    blog_category: [],
    meta_tag: [],
    blog_url: "",
    blog_content: "",
    published: null,
  },
  reducers: {
    setBlog(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBlog.pending, (state) => {
        state.loading = true;
        loaderEvent.emit("showLoader");
      })
      .addCase(fetchBlog.fulfilled, (state, action) => {
        console.log(action.payload);
        state.blog_id = action.payload.blog_id;
        state.blog_title = action.payload.blog_title;
        state.blog_description = action.payload.blog_description;
        state.blog_image = action.payload.blog_image;
        state.meta_keywords = action.payload.meta_keywords.join(", ");
        state.blog_url = action.payload.blog_url;
        state.blog_content = action.payload.blog_content;
        state.blog_category = action.payload.blog_category;
        state.published = action.payload.published;
        state.loading = false;
        loaderEvent.emit("hideLoader");
      })
      .addCase(fetchBlog.rejected, (state) => {
        state.loading = false;
        loaderEvent.emit("hideLoader");
      });
  },
});

export const { setBlog } = blogSlice.actions;
export default blogSlice.reducer;
