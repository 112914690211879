import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

import blogSlice from "./blogSlice";
import userSilice from "./userSilice";

const storeConfig = configureStore({
  reducer: {
    blog: blogSlice,
    user: userSilice,
  },
});

const StoreProvider = ({ children }) => {
  return <Provider store={storeConfig}>{children}</Provider>;
};
export default StoreProvider;
