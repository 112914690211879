import "./index.css";

import { useCallback, useEffect, useState } from "react";

import Blog from "../../component/blogImage";
import { Link } from "react-router-dom";
import loaderEvent from "../../component/loader/loaderEmitter";
import { toast } from "react-toastify";

const Blogs = ({ published }) => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    loaderEvent.emit("showLoader");
    // hideLoader()

    fetch(
      `/api/v2/get-user-blogs${published === true || published === false ? "/?published=" + published : ""}`
    )
      .then((response) => response.json())
      .then((res) => {
        setBlogs(res.data);
      })
      .finally(() => {
        setLoading(false);
        loaderEvent.emit("hideLoader");
      });
  }, [published]);
  const updateBlogPublished = useCallback(
    (index, published) => {
      let newBlogs = blogs;
      newBlogs[index].published = published;
      setBlogs([...newBlogs]);
    },
    [setBlogs, blogs]
  );
  const deleteBlog = useCallback(
    (blog_id) => {
      let newBlogs = blogs;
      loaderEvent.emit("showLoader");
      fetch(`/api/v2/delete-blog/${blog_id}`)
        .then((res) => res.json())
        .then((res) => {
          newBlogs = newBlogs.filter((arr) => arr.post_id !== blog_id);
          setBlogs([...newBlogs]);
          loaderEvent.emit("hideLoader");
          toast.success("Blog deleted")
        });
    },
    [setBlogs, blogs]
  );
  return (
    <>
      {!loading && (
        <div className="row g-3">
          {blogs.map((blog, i) => {
            return (
              <div className="col-12 col-sm-6 " key={i}>
                <div className="card-block blog-box">
                  <Blog
                    blog={blog}
                    index={i}
                    key={blog.blog_id}
                    updateBlogPublished={updateBlogPublished}
                    deleteBlog={deleteBlog}
                  />
                </div>
              </div>
            );
          })}
          {blogs.length === 0 && (
            <>
              <p>Nothing here create a blog </p>
              <h4>
                <Link to={"/create-blog"}>Click here to Create blog</Link>
              </h4>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default Blogs;
